












































import Vue from 'vue'

import { DataTableHeader } from '../../interfaces'

export default Vue.extend({
  name: 'DataTableComponent',

  props: {
    data: Array,
    headers: Array as () => DataTableHeader[],
    itemsPerPage: {
      type: Number,
      default: 10
    }
  },

  data () {
    return {
      search: '',
      expanded: []
    }
  },

  methods: {
    editItem (item: any) {
      this.$emit('editItem', item)
    }
  }
})
